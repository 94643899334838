// Cover Letter Styles
// This file contains common styles used across cover letter pages

// Variables
$background-light: #FDF3F2;
$text-emphasis: 500;
$text-heading: 600;
$border-radius-large: 30px;
$border-radius-medium: 25px;
$border-radius-small: 10px;
$spacing-standard: 20px;
$mobile-breakpoint: 768px;

// Typography
.text-emphasis {
  font-weight: $text-emphasis;
}

.text-heading {
  font-weight: $text-heading;
  margin-bottom: 8px;
  display: inline-block;
}

.subtitle {
  opacity: 90%;
  font-weight: 400;
}

// Layout
.center-content {
  display: flex;
  justify-content: center;
}

.profile-image {
  max-width: max(50vw, 450px);
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: $spacing-standard;
  margin-bottom: 30px;
}

// Callout Sections
.callout-section {
  background-color: $background-light;
  border-radius: $border-radius-large;
  padding: $spacing-standard;
  margin-bottom: $spacing-standard;
  
  .callout-container {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-standard;
    align-items: stretch;
    
    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }
  }
  
  .callout-text {
    flex: 1;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    &.left-aligned {
      padding: 25px 0 0 25px;
      
      @media (max-width: $mobile-breakpoint) {
        order: 2 !important;
        padding: 25px 15px 0;
      }
    }
    
    &.right-aligned {
      padding: 25px 25px 0 10px;
      order: 2;
      
      @media (max-width: $mobile-breakpoint) {
        order: 2 !important;
        padding: 25px 15px 0;
      }
    }
  }
  
  .callout-image {
    flex: 1;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &.right-aligned {
      order: 1;
      
      @media (max-width: $mobile-breakpoint) {
        order: 1 !important;
      }
    }
    
    @media (max-width: $mobile-breakpoint) {
      order: 1 !important;
      min-height: 250px;
    }
    
    .image-container {
      width: 100%;
      height: 100%;
      border-radius: $border-radius-medium;
      overflow: hidden;
      
      @media (max-width: $mobile-breakpoint) {
        height: 250px;
      }
      
      img, video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  
  .section-number {
    color: inherit; // Will be overridden by inline accent color
  }
  
  .section-title {
    font-weight: $text-heading;
    margin-bottom: 8px;
    display: inline-block;
  }
  
  ul, p {
    margin-bottom: 0;
  }
  
  ul.ul {
    padding-left: 16px;
    // list-style-position: inside;
  }
  
  li.li {
    position: relative;
    padding-right: $spacing-standard;
    padding-top: 4px;
    
    &:last-child {
      padding-bottom: 0;
    }
  }
}

// Buttons
.case-study-button {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: -1px;
  
  a {
    text-decoration: none;
  }
  
  .button-content {
    padding: 6px 10px;
    display: inline-block;
    border-radius: $border-radius-small;
    font-weight: 600;
    font-size: 11px;
    transition: all 0.2s ease-in-out;
  }
}

// Button Variants
.button-red {
  background-color: rgba(248, 69, 54, 0.1);
  color: #F84536;
  
  &:hover {
    background-color: #F84536;
    color: white;
  }
}

.button-blue {
  background-color: rgba(0, 132, 255, 0.1);
  color: #0084FF;
  
  &:hover {
    background-color: #0084FF;
    color: white;
  }
}

.button-purple {
  background-color: rgba(146, 107, 228, 0.1);
  color: #926BE4;
  
  &:hover {
    background-color: #926BE4;
    color: white;
  }
}

.button-light-blue {
  background-color: rgba(90, 117, 252, 0.1);
  color: #5A75FC;
  
  &:hover {
    background-color: #5A75FC;
    color: white;
  }
}

.button-gray {
  background-color: rgba(0, 0, 0, 0.05);
  color: #555;
  
  &:hover {
    background-color: #555;
    color: white;
  }
}

// Contact Section
.contact-section {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 60px;
  
  .contact-container {
    background-color: $background-light;
    border-radius: $border-radius-large;
    padding: $spacing-standard 40px;
    display: inline-block;
    
    @media (max-width: $mobile-breakpoint) {
      padding: $spacing-standard;
      width: 100%;
    }
    
    .contact-links {
      display: flex;
      gap: $spacing-standard;
      flex-wrap: wrap;
      justify-content: center;
      
      @media (max-width: $mobile-breakpoint) {
        gap: 10px;
      }
      
      a {
        color: inherit; // Will be overridden by inline accent color
        text-decoration: none;
        font-weight: 500;
      }
      
      .separator {
        color: #888;
      }
      
      .phone {
        color: #555;
      }
    }
  }
} 