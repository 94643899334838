.caption-box {
    outline: 2px solid rgba(176, 176, 176, 0.37);
    padding: 10px;
    border-radius: 5px;
    flex-grow: 1;
}

.caption-text {
    font-family: 'Rubik';
    font-weight: lighter;
    font-size: calc(0.2vw + 12px);
}

.caption-title {
    font-family: 'Rubik';
    font-weight: bold;
    font-size: calc(0.2vw + 12px);
    margin-bottom: 0vh;
}

.image-box {
    padding: 5px;
    box-shadow: 1px 1px 5px lightgrey;
    border-radius: 15px;
    margin-bottom: 2vh;
    min-height: 500px;
    max-height: 60vh;
}

.caption-container {
    margin-left: 3vw;
    margin-right: 3vw;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.project-screen-box {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-bottom: 1vh;
  }

.hide-scroll {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.hide-scroll::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.fade-container {
    margin-top: 5vh;
    position: relative;
    margin-bottom: 5vh;
    align-content: center;
}

.fade-left {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    background:
        linear-gradient(to left, transparent, white);
    height: 100%;
}
.fade-right {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    width:15px;
    background:
        linear-gradient(to right, transparent, white);
    height: 100%;
}

.scroll-container img {
    max-height: 60vh;
}