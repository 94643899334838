.project-announcement-h3 {
    font-weight: bold;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.project-announcement-h2 {
    font-weight: normal;
}

.project-announcement-box-container {
  display: flex;
  justify-content: center;
}

.project-announcement-box {
  text-align: center;
  width: 60vw;
  padding-bottom: 3vh;
}