/* ImageScrollList Styles */
.scroll-container {
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    max-width: 100%;
    display: flex;
    align-items: center;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    overflow-y: hidden; /* Prevent vertical scrollbar */
    min-height: 100px; /* Ensure there's enough space for scrollbar */
    position: relative;
  }
  
  /* Ensure scrollbar appears */
  .scroll-container::-webkit-scrollbar {
    height: 6px; /* Thin scrollbar */
    display: block; /* Force scrollbar to show */
  }
  
  .scroll-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* Light track */
    border-radius: 3px;
  }
  
  .scroll-container::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5); /* Darker thumb */
    border-radius: 3px;
  }
  
  .scroll-container::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.7); /* Slightly darker on hover */
  }
  
  /* Force scrollbar visibility */
  .scroll-container {
    overflow-x: scroll !important;
  }
  
  /* Ensure the scrollbar appears at the bottom and doesn't get hidden */
  .scroll-container::after {
    content: '';
    display: block;
    height: 1px;
  }
  
  /* Scroll Arrows */
  .scroll-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }
  
  .scroll-arrow svg {
    width: 20px;
    height: 20px;
    fill: black;
  }
  
  .scroll-arrow-left {
    left: 10px;
  }
  
  .scroll-arrow-right {
    right: 10px;
  }
  
  /* Show arrows only when scrolling is possible */
  .scroll-container:hover .scroll-arrow {
    opacity: 1;
  }
  