// font sizes - should be mira-adjusted
$fl: 30px;
$fml: 22px;
$fm: 18px;
$fms: 16px;
$fs: 12px;

// font weights - should be mira-adjusted
$fwh: 500;
$fwm: 400;
$fwl: 300;

@media only screen and (min-width: 1500px) {
    .font-large {
        font-size: 28px;
    }

    .font-medium-large {
        font-size: 24px;
    }

    .font-medium {
        font-size: 22px;
    }

    .font-medium-small {
        font-size: 20px;
    }

    .font-small {
        font-size: 18px;
    }

    .font-x-small {
        font-size: 12px;
    }
}

@media only screen and (max-width: 1500px) and (min-width: 1280px) {
    .font-large {
        font-size: 22px;
    }

    .font-medium-large {
        font-size: 20px;
    }

    .font-medium {
        font-size: 18px;
    }

    .font-small {
        font-size: #{$fms};
    }

    .font-x-small {
        font-size: 12px;
    }
}

@media only screen and (max-width: 1280px) {
    .font-large {
        font-size: 20px;
    }

    .font-medium-large {
        font-size: 18px;
    }

    .font-medium {
        font-size: 16px;
    }

    .font-small-reactive {
        font-size: #{$fms};
    }

    .font-x-small {
        font-size: 12px;
    }
}

@media only screen and (max-width: 768px) {
    .font-large {
        font-size: 18px;
    }

    .font-medium-large {
        font-size: 17px;
    }

    .font-medium {
        font-size: 14px;
    }

    .font-small {
        font-size: 15px;
    }

    .font-x-small {
        font-size: 10px;
    }
}