.h1 {
    color: #2b2b2b;
    font-size:35px;
}

.h2 {
    color: #2b2b2b;
    font-size:20px;

}

.h3 {
    color: #2b2b2b;
    font-size:19px;
    font-weight: 500;

}

p, b, a {
    color: #2b2b2b;

}

.p, p, .li, li{
    color: #2b2b2bde;
    font-size:16px;
    font-weight: 400;

}

li {
    margin: 8px 0;
}

.callout {
    background-color:rgba(0, 0, 0, 0.06); 
    padding:40px;
    border-radius:35px;
    margin-bottom:20px;
}

.divider {
    border-bottom: 2px solid rgba(0, 0, 0, 0.089);
    margin-bottom:30px;
    margin-top:30px;
}

.tag {
    padding: 8px 12px 8px 12px;
    background-color: rgba(0, 176, 53, 0.169);
    color:rgb(2, 141, 44);
    display:inline-block;
    border-radius: 12px;
    margin-left: 4px;    
    font-weight: 600;
    font-size: 12px;
}